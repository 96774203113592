import React from 'react';
import {Route, useParams} from 'react-router-dom';
import ManualPage from './manualPage';
import ManualHome from './manualHome';
import SideBar from '../../navigation/sidebar';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupWrokIcon from '@material-ui/icons/GroupWork';

const MANUAL_PAGES = [
    {url: 'registration', label: 'Register'},
    {url: 'list', icon: FormatListBulletedIcon, label: 'List', children : [
        {url: 'add-list', label: 'Add List'},
        {url: 'add-item', label: 'Add List Item'},
        {url: 'import-item', label: 'Import List Item'},
        {url: 'list-items', label: 'List Items'},
        {url: 'assign-location', label: 'Assign Location'},
        {url: 'known-locations', label: 'Known Locations'}
    ]},
    {url: 'store', icon: BusinessIcon, label: 'Store', children : [
        {url: 'add-store', label: 'Add Store'},
        {url: 'add-aisles', label: 'Add Aisles'},
        {url: 'add-sections', label: 'Add Sections'},
        {url: 'import-sections', label: 'Import Sections'}
    ]},
    {url: 'categories', icon: GroupWrokIcon, label: 'Categories', children: [
        {url: 'add-category', label: 'Add a Category'}
    ]},
    {url: 'settings', icon: SettingsIcon, label: 'Settings'}
];

export default ({match}) => {
    const {page} = useParams();
    return (
        <div>
            <h2>Using the Super Shopper app</h2>
            <div id="manualContainer">
                <SideBar className="manual-nav" routes={MANUAL_PAGES} path={match.url} />
                {!!page && !!page.length && <ManualHome />}
                <Route exact path="/manual/:page" component={ManualPage} />
                <Route  path="/manual/:page/:content" component={ManualPage} />
            </div>
        </div>
    );
}