import React from 'react';
import logo from '../../../images/registration.png';

export default () => {
    return (
        <div className='manual-container'>
            <h3>Registration</h3>
            <div className="manual-content">
                <section>
                    <img src={logo} className="manual-screenshot" alt="Registration Screen Capture" />
                    <p>
                        Creating an account is easy. Simply fill out the form and press submit. When your account is created
                        an email will be sent to the email address provided. The email will contain a verification code.
                        Input the verification code to confirm your account. Once your account has been confirmed you 
                        will be able to login.
                    </p>
                    <p>
                        By creating an account each user agrees to the <a href="https://spanglersoftware.com/legal"  rel="noopener noreferrer" target="_blank">Spangler Software Terms &amp; Conditions</a>
                    </p>
                </section>
            </div>
        </div>
    );
}