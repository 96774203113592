import React from 'react';
import {Link} from 'react-router-dom';
import img from '../../../images/category_list.png';
import GroupWrokIcon from '@material-ui/icons/GroupWork';

export default () => {
    return (
        <div className='manual-container'>
            <h3><GroupWrokIcon className="app-icon" /> Category List</h3>
            <div  className="manual-content">
                <section>
                    <img src={img}  className="manual-screenshot" alt="Category List Screen Capture" />
                    <p>
                        Categories can be managed on the Categories tab of the application. 
                        Categories can be added by tapping the <Link to="/manual/categories/add-category">Add New Cateogry</Link> link.
                        A Category can be deleted by swiping left on the Category. A long swipe will delete it immediatly, a small swipe will 
                        reveal the delete button.
                    </p>
                </section>
            </div>
        </div>
        
    );
}