import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../../images/store_form.png';
import BusinessIcon from '@material-ui/icons/Business';

export default () => {
    return (
        <div className='manual-container'>
            <h3><BusinessIcon className="app-icon" /> Stores</h3>
            <div className="manual-content">
                <section>
                    <img src={logo} className="manual-screenshot" alt="Store Screen Capture" />
                    <p>
                        Creating a store is simple. Search for a store by entering a name, address, city, or zip code into 
                        the name text box. If the drop down contains the store you want to add simply select it and
                        click the <i className="nowrap">Save Store</i> button. Otherwise, fill out the form and save the store.
                    </p>
                    <p>
                        Once a store has been created, it will need to be populated with Aisles and/or Sections.
                    </p>
                    <ul>
                        <li><Link to="/manual/store/add-aisles">Adding Aisles</Link></li>
                        <li><Link to="/manual/store/add-sections">Adding Sections</Link></li>
                        <li><Link to="/manual/store/import-sections">Import Sections</Link></li>
                    </ul>                        
                </section>
            </div>
        </div>
    );
}