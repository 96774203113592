import React from 'react';
import img from '../../../images/settings.png';
import SettingsIcon from '@material-ui/icons/Settings';

export default () => {
    return (
        <div className='manual-container'>
            <h3><SettingsIcon className="app-icon" /> Settings</h3>
            <div className="manual-content">
                <section>
                    <img src={img} className="manual-screenshot" alt="App Settings Screen Capture" />
                    <p>
                        The Super Shopper app provides users with the abilty to toggle dark mode within the app. This will
                        transform the presentation of the app to a darker appearance.
                    </p>
                </section>
            </div>
        </div>
    );
}