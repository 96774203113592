import React from 'react';
import img from '../../../images/assign_location.png';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export default () => {
    return (
        <div className='manual-container'>
            <h3><FormatListBulletedIcon className="app-icon" /> Assign List Item Location</h3>
            <div  className="manual-content">
                <section>
                    <img src={img}  className="manual-screenshot" alt="Shopping List Screen Capture" />
                    <p>
                       Each item can be assigned one location per store. Tap the location where the item 
                        is located to assign it to that location for the store.
                    </p>
                </section>
            </div>
        </div>
        
    );
}