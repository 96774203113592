import {submitFeedback, validateRecaptchaToken} from '../../api/support/supportApi';
import {
    SUBMIT_SUPPORT_REQUEST, 
    SUBMIT_SUPPORT_REQUEST_SUCCESS, 
    RESET_SUPPORT,
    VALIDATE_RECAPTCHA_TOKEN,
    VALIDATE_RECAPTCHA_TOKEN_SUCCESS} from './supportTypes';

export function submitSupportRequest(topic, email, comments) {
    return (dispatch, getState) => {
        dispatch({type: SUBMIT_SUPPORT_REQUEST});
        submitFeedback(topic, email, comments).then(response => {
            dispatch({
                type: SUBMIT_SUPPORT_REQUEST_SUCCESS,
                response
            });
        });
    }
}

export function resetSupportState() {
    return (dispatch, getState) => {
        dispatch({type: RESET_SUPPORT});
    }
}

export function validateUserRecaptchaToken(token) {
    return (dispatch, getState) => {
        if (!token || !token.length) {
            dispatch({
                type: VALIDATE_RECAPTCHA_TOKEN_SUCCESS, 
                response: {isValid : false}
            });
        }
        else {
            dispatch({type: VALIDATE_RECAPTCHA_TOKEN});
            validateRecaptchaToken(token).then(response => {
                dispatch({
                    type: VALIDATE_RECAPTCHA_TOKEN_SUCCESS,
                    response
                });
            })
        }
    }
}