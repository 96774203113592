import axios from 'axios';

const RECAPTCHA_URL = 'https://spanglersoftware.com/api/auth/valid-recaptcha-token.php?token=';
const SUPPORT_BASE = 'https://speedyshopper.spanglersoftware.com/api/support/index.php';

export function submitFeedback(topic, email, comments) {
    return axios.post(SUPPORT_BASE, {
        topic: topic,
        email: email,
        comments: comments
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        if (error && error.response) {
            if (error.response.data && Object.keys(error.response.data).length) {
                return error.response.data;
            }

            return {error: error.response.statusText};
        }

        return {error: 'An unexpected error occurred. ' + error};
    })
}

export function validateRecaptchaToken(token) {
    return axios.get(RECAPTCHA_URL + token)
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return {isValid: false};
    });
}