import React from 'react';
import {BrowserRouter, Route, Switch, NavLink} from 'react-router-dom';
import Home from './home/home';
import TermsLink from './terms/terms';
import Manual from './support/manual';
import InlineMenu from './navigation/inlineMenu';
import SupportForm from './support/support';
import Header from './header/header';

const MAIN_LINKS = [
  {url: '/', title: 'The App', isExact : true},
  {url: '/manual/registration', title: 'Manual', isExact : false},
  {url: '/support', title: 'Support', isExact : true}
];

export default () => {
  const mainNav = MAIN_LINKS.map((link, index) => <NavLink key={index} exact={link.isExact} to={link.url}>{link.title}</NavLink>);
  mainNav.push(<TermsLink />);
  return (
    <BrowserRouter basename="/">
      <Header />
      <div id="content">
          <nav id="mainNav">
          <InlineMenu children={mainNav} />
          </nav>
          <div id="contentContainer">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route name="manual" path="/manual" component={Manual} />
              <Route exact path="/support" component={SupportForm} />
            </Switch>
          </div>
      </div>
    </BrowserRouter>
  );
}
