import React from 'react';
import img from '../../../images/new_category.png';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

export default () => {
    return (
        <div className='manual-container'>
            <h3><GroupWorkIcon className="app-icon" /> Add a Category</h3>
            <div  className="manual-content">
                <section>
                    <img src={img}  className="manual-screenshot" alt="New Category Screen Capture" />
                    <p>
                        To create new Category, select the color for the category (indicated by the black outline), and type a name in the input box and tap the save button.
                        Categories cannot be dupliacates and names are case insensitive. This means that the Cateogry name <i>'Grocery'</i> would be a duplicate of <i>'grocery'</i>.
                    </p>
                </section>
            </div>
        </div>
        
    );
}