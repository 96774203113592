import React from 'react';
import img from '../../../images/add_item.png';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export default () => {
    return (
        <div className='manual-container'>
            <h3><FormatListBulletedIcon className="app-icon" /> Add Item to List</h3>
            <div  className="manual-content">
                <section>
                    <img src={img}  className="manual-screenshot" alt="Shopping List Screen Capture" />
                    <p>
                        Adding an item to a list is quite simple. Begin by typing the item's name 
                        into the name field in the form. The app will search for items with the same name. Tapping
                        an auto-complete option will populate the remaining fields, if the data is present.
                    </p>
                    <p>
                        The only field that is required is the Name. Brand and Size are additional fields
                        that allow you to be more specific with your list items.
                    </p>
                </section>
            </div>
        </div>
        
    );
}