import React, {Component} from 'react';
import logo from '../images/logoTransparentBackground.png';
import {Switch, Route} from 'react-router-dom';

export default class Header extends Component {
    render() {
        return (
            <header>
                <a href="/"><img src={logo} alt="Super Shopper Logo" /></a>
                <div>
                    <h1><a style={{color: '#fff'}} href="/">Speedy Shopper</a></h1>
                    <Switch>
                        <Route exact path="/" render={() => <p>The App</p>} />
                        <Route path="/manual" render={() => <p>Manual</p>} />
                        <Route exact path="/support" render={() => <p>Support</p>} />
                    </Switch>
                </div>
            </header>
        );
    }
}