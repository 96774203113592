import React, {Component} from 'react';
import {submitSupportRequest, resetSupportState, validateUserRecaptchaToken} from '../service/support/supportService';
import { connect } from 'react-redux';
import { RECAPTCHA_SITE_KEY } from '../auth/recaptchaProperties';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'

class SupportForm extends Component {
    constructor() {
        super();
        this.state = {
            topic : null,
            comments : null,
            email : null,
            isFormInvalid : false
        };
    }

    componentDidMount() {
        this.props.dispatch(resetSupportState);
        loadReCaptcha(RECAPTCHA_SITE_KEY);
    }

    resetForm() {
        this.setState({
            topic: null,
            comments : null,
            email: null
        });
        this.props.dispatch(resetSupportState());
    }

    onTopicChanged = (event) => {
        const select = event.currentTarget;
        this.setState({topic: select.options[select.selectedIndex].value});
    };

    onCommentsChanged = (event) => {
        const textarea = event.currentTarget;
        this.setState({comments: textarea.value});
    }

    onEmailChanged = (event) => {
        const input = event.currentTarget;
        const text = input.value;
        if (text.match(/^.+@.+\.[a-z]{3}$/g)) {
            this.setState({email: text});
        }
    }

    submitForm = () => {
        const {topic, comments, email} = this.state;
        if (!topic || !topic.trim().length 
            || !comments || !comments.trim().length
            || !email || !email.trim().length) {
            this.setState({isFormInvalid: true});
        }
        else {
            this.setState({isFormInvalid: false});
            this.props.dispatch(submitSupportRequest(topic, email, comments));
        }
    }

    recaptchaCallback = (token) => {
        this.props.dispatch(validateUserRecaptchaToken(token));
    }

    render() {
        const {isLoading, apiError, isRequestSubmitted, isRecaptchaValid} = this.props.supportState;
        const hasApiError = !!(apiError && apiError.length);
        const isSuccessfulSubmission = !hasApiError && !!isRequestSubmitted;

        if (isSuccessfulSubmission) {
            return (
                <div id="supportForm" className="single-column-container">
                    <p>
                        Request has been received. We will respond to the email address provided as soon as possible. Thank you for using Speedy Shopper
                    </p>
                    <button onClick={() => this.resetForm()}>Create New Request</button>
                </div>
            );
        }

        if (!isRecaptchaValid) {
            return (
                <div id="supportForm" className="single-column-container">
                    <p className="error-text">The current session could not be determined to be authentic. Please refresh the page.</p>
                </div>
            );
        }

        return (
            <div id="supportForm" className="single-column-container">
                <div>
                    {hasApiError &&
                    <p className="error-text">Failed to create support request: {apiError}</p>
                    }
                    <p>
                        Please submit the form below to get assitance regarding the Speedy Shopper mobile application.
                    </p>
                    <div>
                        <div>
                            <label><strong>Topic</strong></label>
                        </div>
                        <div>
                        <select required onChange={this.onTopicChanged}>
                            <option value="">Select Topic</option>
                            <option value="error">Technical Assistance</option>
                            <option value="account">Account</option>
                            <option value="feedback">Feedback</option>
                        </select>
                        </div>
                    </div>
                    <div>
                        <div><label><strong>Email</strong></label></div>
                        <input type="email" onChange={this.onEmailChanged} />
                    </div>
                    <div>
                        <div>
                        <label><strong>Comments</strong></label>
                        </div>
                        <div>
                        <textarea required onChange={this.onCommentsChanged}></textarea>
                        </div>
                    </div>
                    {this.state.isFormInvalid &&
                    <p className="error-text">
                        A topic, email and question(s) / comment(s) are required
                    </p>
                    }
                    <ReCaptcha
                        sitekey={RECAPTCHA_SITE_KEY}
                        action='ss_support'
                        reCaptchaType='invisible'
                        verifyCallback={this.recaptchaCallback} />
                    <div>
                        <button onClick={this.submitForm} className="form-button">Submit</button>
                    </div>
                </div>
                {isLoading &&
                <div className="loader"></div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {supportState} = state;
    return {
        supportState
    };
}

export default connect(mapStateToProps)(SupportForm);