import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export default () => {
    return (
        <div className='manual-container'>
            <h3>App Manual</h3>
            <div className="manual-content">
                <section>
                    <p>
                        <ChevronLeftIcon />
                        Find help and information regarding how to use the Super Shopper App
                        with the links on the left.
                    </p>
                </section>
            </div>
        </div>
    );
}