import React from 'react';
import './App.scss';
import {Provider} from 'react-redux';
import Store from './store';
import Main from './main';

export default () => {
  return (
    <Provider store={Store}>
      <Main />
    </Provider>
  );
}
