import * as types from '../service/support/supportTypes';

const initialState = {
    isLoading : false,
    isRequestSubmitted : false,
    apiError : null,
    isRecaptchaValid : true
};

export default (state = initialState, action) => {
    const {response} = action;
    switch (action.type) {
        case types.RESET_SUPPORT : 
            return {
                isLoading: false,
                isRequestSubmitted : false,
                apiError : null
            }
        
        case types.SUBMIT_SUPPORT_REQUEST : 
            return {
                ...state,
                isLoading : true,
                isRequestSubmitted : false,
                apiError : null
            }

        case types.SUBMIT_SUPPORT_REQUEST_SUCCESS : 
            return {
                ...state,
                isLoading: false,
                isRequestSubmitted : true,
                apiError : (response && response.error) ? response.error : null
            }

        case types.VALIDATE_RECAPTCHA_TOKEN : 
            return {
                ...state,
                isLoading : true
            }
            
        case types.VALIDATE_RECAPTCHA_TOKEN_SUCCESS :
            return {
                ...state,
                isLoading: false,
                isRecaptchaValid : response.isValid
            }
            
        default : return state;
    }
}