import React from 'react';
import logo from '../../../images/list_item_menu.png';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { Link } from 'react-router-dom';

export default () => {
    return (
        <div className='manual-container'>
            <h3><FormatListBulletedIcon className="app-icon" /> List Items</h3>
            <div className="manual-content">
                <section>
                    <img src={logo} className="manual-screenshot" alt="List Item Screen Capture" />
                    <p>
                        Each item in a list has an overflow menu. This menu is only available once a store has been selected.
                        The menu provides two different actions.
                    </p>
                    <ol className="overflow-auto">
                        <li><Link to="/manual/list/assign-location">Assign Location</Link></li>
                        <li><Link to="/manual/list/known-locations">Known Locations</Link></li>
                    </ol>
                    <p>
                        To remove an item from a list simply swipe the item left. If the item is swiped
                        all the way to the left, it will be removed immediately. Otherwise, a delete button 
                        will appear and can be tapped to remove the item from the list.
                    </p>
                </section>
            </div>
        </div>
    );
}