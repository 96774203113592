import React from 'react';
import img from '../../../images/add_store_isles.png';
import BusinessIcon from '@material-ui/icons/Business';

export default () => {
    return (
        <div className='manual-container'>
            <h3><BusinessIcon className="app-icon" /> Add Aisles</h3>
            <div className="manual-content">
                <section>
                    <img src={img} className="manual-screenshot" alt="Store Sections Screen Capture" />
                    <p>
                        A store can have up to seventy (70) Aisles. The dropdown can be used to select the maximum aisles to add.
                        Once the number of aisles has been selected, tap the <i class="nowrap">Add Aisles</i> button and the Aisles
                        will be added to the store.
                    </p>
                </section>
            </div>
        </div>
    );
}