import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../../images/store.png';
import BusinessIcon from '@material-ui/icons/Business';

export default () => {
    return (
        <div className='manual-container'>
            <h3><BusinessIcon className="app-icon" /> Stores</h3>
            <div className="manual-content">
                <section>
                    <img src={logo} className="manual-screenshot" alt="Store Screen Capture" />
                    <p>
                        A store represents a brick and mortar store, with a physical address. Each store contains a 
                        collection of Aisles and Sections.
                        <dl>
                            <dt>Aisle</dt>
                            <dd>A number based shopping aisle.</dd>
                            <dt>Section</dt>
                            <dd>A name based shopping location that is not an Aisle. Sections could include
                                Aisle endcaps, loose item shopping areas, or Islands within a store.
                            </dd>
                        </dl>

                    </p>
                    <p>
                        Once a store has been created, it will need to be populated with Aisles and/or Sections.
                    </p>
                    <ul>
                        <li><Link to="/manual/store/add-aisles">Adding Aisles</Link></li>
                        <li><Link to="/manual/store/add-sections">Adding Sections</Link></li>
                        <li><Link to="/manual/store/import-sections">Import Sections</Link></li>
                    </ul>                        
                </section>
            </div>
        </div>
    );
}