import React, {Component} from 'react';
import {NavLink} from "react-router-dom"
import PropTypes from 'prop-types';

export default class SideBar extends Component {
    
    createRouteItem = (route, path) => {
        const hasChildren = !!(route.children && route.children.length);
        return (
            <li key={route.url}>
                <NavLink className={hasChildren ? 'nav-expand' : ''} exact={route.isExact} to={`${path}/${route.url}`}>
                    {!!route.icon && <route.icon fontSize={'small'} />}
                    {route.label}
                </NavLink>
                {hasChildren &&
                    this.createRouteList(route.children, (`${path}/${route.url}`))
                }
            </li>
        );
    }

    createRouteList = (routes, path) => {
        return (
            <ul className="menu-list">
                {routes.map(route => this.createRouteItem(route, path))}
            </ul>
        );
    }

    render() {
        return (
            <div id="sidebar-nav" className={this.props.className}>
                <ul>
                    {this.createRouteList(this.props.routes, this.props.path)}
                </ul>
            </div>
        );
    }
}

SideBar.propTypes = {
    routes : PropTypes.array.isRequired,
    path : PropTypes.string.isRequired
};