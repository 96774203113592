import React from 'react';
import { useParams } from 'react-router-dom';
import Store from './store/store';
import AddStore from './store/addStore';
import List from './list/list';
import Registration from './registration/registration';
import ListItem from './list/listItem';
import StoreSections from './store/storeSections';
import AddStoreIsle from './store/addStoreIsle';
import AddStoreSection from './store/addStoreSection';
import ImportSections from './store/importSections';
import NewCategory from './category/newCategory';
import CategoryList from './category/categoryList';
import Settings from './settings/settings';
import ManualHome from './manualHome';
import AddListItem from './list/addListItem';
import KnownLocations from './list/knownLocations';
import ImportListItem from './list/importListItem';
import AssignLocation from './list/assignLocation';
import AddList from './list/addlist';

export default () => {
    const {page, content} = useParams();
    
    switch (page) {
        case 'registration' : 
            return <Registration />
        case 'list' : 
            switch(content) {
                case 'add-list' :
                    return <AddList />
                case 'list-items': 
                    return <ListItem />
                case 'add-item' : 
                    return <AddListItem />
                case 'import-item' :
                    return <ImportListItem />
                case 'assign-location' :
                    return <AssignLocation />
                case 'known-locations' :
                    return <KnownLocations />
                default : 
                    return <List />
            }
        case 'store' : 
            switch (content) {
                case 'add-aisles':
                    return <AddStoreIsle />
                case 'add-sections' :
                    return <AddStoreSection />
                case 'store-sections' :
                    return <StoreSections />
                case 'import-sections' : 
                    return <ImportSections />
                case 'add-store' :
                    return <AddStore />
                default :
                    return <Store />
            }
        case 'categories' :
            switch (content) {
                case 'add-category' : 
                    return <NewCategory />
                default : 
                    return <CategoryList />
            }
        case 'settings' :
            return <Settings />
        default :
            return <ManualHome />
    }
}