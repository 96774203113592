import React from 'react';
import img from '../../../images/known_locations.png';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export default () => {
    return (
        <div className='manual-container'>
            <h3><FormatListBulletedIcon className="app-icon" /> Known Locations</h3>
            <div  className="manual-content">
                <section>
                    <img src={img}  className="manual-screenshot" alt="Shopping List Screen Capture" />
                    <p>
                        Known locations represent other locations within the store where other people found the item.
                        This can be helpful if you do not know where an item is located. The left column indicates the location
                        where the item was found. The right column indicates how many other people are using that location for the item.
                        By tapping on the location in the left column, it will assign the location for the current store / item combination.
                    </p>
                </section>
            </div>
        </div>
        
    );
}