import React from 'react';
import img from '../../../images/list_items.png';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export default () => {
    return (
        <div className='manual-container'>
            <h3><FormatListBulletedIcon className="app-icon" /> Shopping Lists</h3>
            <div  className="manual-content">
                <section>
                    <img src={img}  className="manual-screenshot" alt="Shopping List Screen Capture" />
                    <p>
                        Lists contain all the items you may be shopping for in one trip. Items can easily be added by tapping the <i className="nowrap">Add New Item</i> link at the bottom of the list, or selecting the <i className="nowrap">Add New Item</i> option from the overflow menu.
                        Tapping the <i className="nowrap">Add Items From Lists</i> will allow you to import items from other lists into the currently selected list.
                    </p>
                    <p>
                        Lists can be assigned a Category by tapping the Category field just under the list name.
                    </p>
                    <p>
                        Tapping the sort icon will toggle the items to be sorted in Ascending or Descending order by their respective
                        aisle or section. <br /><i>Note: this is only available once a store has been selected</i><br />
                    </p>
                </section>
            </div>
        </div>
        
    );
}