import React, {Component} from 'react';
import importSectionsImg from '../../../images/import_store_sections.png';
import importedSectionsImg from '../../../images/imported_store_sections.png';
import BusinessIcon from '@material-ui/icons/Business';

export default class ImportSections extends Component {
    constructor() {
        super();
        this.state = {
            isImportedImage : false
        }
    }

    toggleImage = () => {
        this.setState({isImportedImage : !this.state.isImportedImage});
    }

    render() {
        const {isImportedImage} = this.state;
        const img = isImportedImage ? importedSectionsImg : importSectionsImg;
        const anchorText = 'View sections' + (isImportedImage ? ' not' : '') + ' imported';
        return (
            <div className='manual-container'>
                <h3><BusinessIcon className="app-icon" /> Import Sections</h3>
                <div className="manual-content">
                    <section>
                        <img src={img} className="manual-screenshot" alt="Store Sections Screen Capture" />
                        <p>
                            If Store Sections and/or Aisles are found, they may be imported on this screen.
                            Once an Aisle / Section has been imported, it will appear with grey strike through font.
                            Aisles and sections cannot be removed from a store on this scren, only imported. 
                            The toggle all button at the toggle all button at the top of the screen will toggle
                            and Aisles and/or Sections that have not been imported.
                        </p>
                        <button className="form-button" onClick={this.toggleImage}>{anchorText}</button>
                    </section>
                </div>
            </div>
        );
    }
}