import React from 'react';
import logo from '../../../images/sort_store_sections.png';

export default () => {
    return (
        <div className='manual-container'>
            <h3>Store Sections</h3>
            <div className="manual-content">
                <section><img src={logo} className="manual-screenshot" alt="Store Sections Screen Capture" /></section>
                <section>
                    <p>
                        Once the Aisles and Sections have been added/imported in a store, they can easily be sorted.
                        Sectoins have two horizontals bars that when held, will allow the section to be moved up or down the list.
                        The section will turn a light blue indicating it can be moved. Once it has been moved to the desired position,
                        releasing the section will drop it into place.
                    </p>
                </section>
            </div>
        </div>
    );
}