import React from 'react';
import img from '../../../images/import_items.png';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export default () => {
    return (
        <div className='manual-container'>
            <h3><FormatListBulletedIcon className="app-icon" /> Import List Item</h3>
            <div  className="manual-content">
                <section>
                    <img src={img}  className="manual-screenshot" alt="Shopping List Screen Capture" />
                    <p>
                        Importing items into a list only includes items that are currently on lists other than 
                        the current list. The search box at the top can be used to find specific items. 
                        Select the items to import and tap the <i class="nowrap">Add Items</i> button.
                    </p>
                </section>
            </div>
        </div>
        
    );
}