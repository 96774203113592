import React from 'react';
import img from '../../../images/add_list.png';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

export default () => {
    return (
        <div className='manual-container'>
            <h3><FormatListBulletedIcon className="app-icon" /> Create a List</h3>
            <div  className="manual-content">
                <section>
                    <img src={img}  className="manual-screenshot" alt="Shopping List Screen Capture" />
                    <p>
                        Lists are used to store a collection of items. Each list should have a case insensitive name.
                        That means if a list with the name <i class="nowrap">New List</i> exists, another list with the 
                        name <i class="nowrap">new list</i> cannot be created.
                    </p>
                    <p>
                        The list can be assigned a category by tapping the category name under the list name input. The category 
                        can be changed any time after the list has been created.
                    </p>
                </section>
            </div>
        </div>
        
    );
}