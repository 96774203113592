import React from 'react';
import img from '../../../images/add_store_section.png';
import BusinessIcon from '@material-ui/icons/Business';

export default () => {
    return (
        <div className='manual-container'>
            <h3><BusinessIcon className="app-icon" /> Add Sections</h3>
            <div className="manual-content">
                <section>
                    <img src={img} className="manual-screenshot" alt="Store Sections Screen Capture" />
                    <p>
                        Sections can be added to the store by giving it a proper name.  Section names are case insensitive. 
                        This means if a Section with the name <i>Produce</i> exists, another section with the name <i>produce</i> cannot
                        be created.
                    </p>
                </section>
            </div>
        </div>
    );
}