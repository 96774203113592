import React, {Component} from 'react';
import PropTypes from 'prop-types';

class InlineMenu extends Component {
    render() {
        return (
            <ul className={(this.props.listClassName || '') + ' inline-menu'}>
                {this.props.children.map((item, index) => <li key={index.toString()}>{item}</li>)}
            </ul>
        );
    }
};

InlineMenu.propTypes = {
    children : PropTypes.array.isRequired,
    listClassName : PropTypes.string
};

export default InlineMenu;