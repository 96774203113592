import React from 'react';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

export default () => {
  return (
    <div className="single-column-container">
      <div id="homeContent" className="center-text">
        <h2>The Speedy Shopper app is now available</h2>
        <h3>Download the app today!</h3>
        <ul className="flex-row space-around">
          <li><a href="https://play.google.com/store/apps/details?id=com.supershopper" rel="noopener noreferrer" target="_blank"><AndroidIcon fontSize="small" /> Android</a></li>
          <li><a href="https://apps.apple.com/app/apple-store/id1496072776?mt=8" rel="noopener noreferrer" target="_blank"><AppleIcon fontSize="small" /> iOS</a></li>
        </ul>
        <h3>What is the Speedy Shopper?</h3>
        <p>
          The Speedy Shopper is productivity app intended to help improve your common shopping errands. In addition to creating
          various shopping lists, the Speedy Shopper allows lists to work with multiple stores.
        </p>
        <ul>
          <li>
            <h4>List Management</h4>
            <p>
              The Speedy Shopper remembers where each item was found in each store. This means you can use the same list for multiple stores
              and quickly switch between stores as needed. Items in a list can be sorted in ascending or descending order based on their location to make 
              a shopping trip a quick as possible.
            </p>
          </li>
          <li>
              <h4>Store Management</h4>
              <p>
                Stores are based on their address, but can be given a customizable name. Stores contain Aisles and Sections. The Speedy Shopper
                allows users to only add the Aisles and Sections they desire in a store. Once the Sections have be established, they can be ordered 
                with simple drag and drop to organize the store as bests suits each user.
              </p>
          </li>
          <li>
            <h4>Categories</h4>
            <p>Color coded categories allow lists and stores to be grouped together keeping data contained within its respective group.</p>
          </li>
        </ul>
        
      </div> 
    </div>
  );
}